:root {
  --angle-btn: 180deg;
  --gradX: 80%;
  --gradY: 50%;
  --color1: #1cb2c7;
  --color2: #183070;
}
.btn {
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
    z-index: 40;

  color: #9a9b9b;
  /* font-family: Nunito Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;

  /* cursor: pointer; */

  border: 1px solid var(--color2);
  /* border-image: conic-gradient(
      from var(--angle-btn),
      var(--color2),
      var(--color1) 0.1turn,
      var(--color1) 0.2turn,
      var(--color1) 0.3turn,
      var(--color1) 0.4turn,
      var(--color2) 0.5turn
    )
    20; */
  border-image: radial-gradient(
      ellipse at var(--gradX) var(--gradY),
      var(--color2) 30%,
      var(--color1) 70%
    )
    20;
  transition: 0.5s;
}
.btn:hover {
  border-image: conic-gradient(
      from var(--angle-btn),
      var(--color1),
      var(--color1) 0.1turn,
      var(--color1) 0.2turn,
      var(--color1) 0.3turn,
      var(--color1) 0.4turn,
      var(--color1) 0.5turn
    )
    20;
  /* border-image: radial-gradient(
      ellipse at var(--gradX) var(--gradY),
      var(--color2) 0%,
      var(--color1) 100%
      )
      20; */
  /* border-image: none;
  border-color: var(--color1); */
  transition: 0.5s;
}
@media (width <520px) {
    .btn {
        padding: 8px 12px;
        font-size: 12px;
    }
}

.header-heading {
  width: var(--max-widht);
  padding: 0 4.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 80px;
  margin-top: 100px;
}
.header-line {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.header-line_block {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.header-small-description {
  box-sizing: border-box;
  font-family: JetBrains Mono, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  color: var(--primary-color);
  display: block;
  margin-left: 42px;
  padding-top: 22px;
  letter-spacing: 2px;
  max-width: 150px;
  z-index: 8;
}
.header-subtitle {
  position: relative;
  display: block;
  margin-left: 32px;
  margin-top: 30px;
  z-index: 8;
}
.description {
  display: block;
  color: var(--primary-color);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 22px;
  margin-top: 30px;
  z-index: 8;
}
.description-ru {
  max-width: 612px;
}
.description-en {
  max-width: 460px;
}
.header-product-list {
  margin: 0 auto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  color: var(--secondary-color);
  z-index: 8;
}
.header-product {
  margin-left: 10px;
}
.header-scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 20px;
  width: 100%;
}
.header-images {
  position: relative;
}
.header-image {
  position: absolute;
  top: -8.125rem;
  z-index: 1;
  width: 40.375rem;
  height: 22.8125rem;
}
.header-image-small {
  position: absolute;
  z-index: 1;
  top: -9.5rem;
  left: -10rem;
  width: 26rem;
  height: 14.69rem;
}
.image-ru {
  left: -7.5rem;
}
.image-en {
  left: 2.5rem;
}
.header-ellipse {
  position: absolute;
  top: -200px;
  left: 90px;
  width: 182px;
  height: 429px;
  transform: rotate(-57.445deg);
  flex-shrink: 0;
  border-radius: 429px;
  background: #0892df;
  filter: blur(250px);
  z-index: 4;
}
.header-circle {
  position: absolute;
  top: 200px;
  left: 330px;
  width: 146px;
  height: 145px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0892df;
  filter: blur(150px);
  z-index: 4;
}
/* start media */
@media (width < 1440px) {
  .header-heading {
    width: 100%;
    min-width: 100%;
  }
}
@media (width <= 1300px) {
  .header-heading {
    gap: 4rem;
  }
  .header-line_block {
    flex-direction: column-reverse;
  }
  .description {
    padding-top: 0;
    margin-top: 4rem;
  }
  .header-small-description {
    max-width: 300px;
    padding-top: 0;
    margin-left: 0;
    margin-bottom: 1rem;
  }
  .header-subtitle {
    margin-left: 0;
    margin-top: 0;
  }
  .header-image {
    top: -14.5rem;
    width: 38rem;
    height: 21.47rem;
  }
  .image-ru {
    left: -9.5rem;
  }
  .image-en {
    left: 1rem;
  }
}
@media (width <1000px) {
  .header-heading {
    padding: 0 1.6rem;
  }

  .header-small-description {
    font-size: 0.8rem;
  }

  .description {
    font-size: 1.4rem;
  }

  .header-product-list {
    font-size: 1.2rem;
    margin: 0 auto 0 0;
  }

  .header-subtitle {
    margin-top: 0;
  }

  .header-scroll {
    display: none;
  }
  .header-image {
    top: -10.5rem;
    width: 26rem;
    height: 14.69rem;
  }
  .image-ru {
    left: -5.5rem;
  }
  .image-en {
    left: 2rem;
  }
  .header-ellipse {
    display: none;
  }
  .header-circle {
    display: none;
  }
}
@media (width <600px) {
  .header-image {
    top: -9.5rem;
    width: 26rem;
    height: 14.69rem;
  }
  .image-ru {
    left: -6.75rem;
  }
  .image-en {
    left: -1rem;
  }
}
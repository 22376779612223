.vacancy {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0 0 6.25rem 0;
}
.header_vacancy_container {
  min-width: var(--min-widht);
  width: var(--max-widht);
  padding: 0 4.375rem;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.header_vacancy h1,
.header2_vacancy h1 {
  width: max-content;
  white-space: pre-wrap;
}
.header_vacancy_container p {
  color: var(--primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  width: 50%;
  margin-left: 5%;
  margin-bottom: 165px;
  padding-top: 10px;
  white-space: pre-wrap;
}

/*  */
.container_vacancy {
  min-width: var(--min-widht);
  width: var(--max-widht);
  padding: 0 4.375rem;
  margin: 0 auto;
}
.vacancy_item {
  /* all: initial; */
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
  width: 100%;
  white-space: pre-wrap;
  border-bottom: 1px solid var(--line-vacancy);
  padding-bottom: 19px;
}
.vacancy_item:not(:first-child) {
  padding-top: 80px;
}
.vacancy_item > div {
  width: 250px;
}
.vacancy_item > div:first-child {
  align-self: flex-end;
  width: 306px;
  color: var(--secondary-color);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.vacancy_item > div:not(:first-child):not(:last-child) {
  align-self: flex-end;
  color: var(--secondary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.vacancy_item > div:not(:first-child):not(:last-child) p:first-child {
  color: var(--third-color);
  padding-bottom: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.vacancy_item > div:last-child {
  align-self: flex-end;
  align-items: flex-end;
  width: auto;
  margin-right: 79px;
}
/* .btn-flash-circle {
  position: absolute;
  top: -75px;
  left: -50px;
  width: 146px;
  height: 145px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0892df;
  filter: blur(150px);
  z-index: -1;
  transition: 1s;
} */
.vacancy_item > div:last-child:hover .btn-flash-circle {
  width: 325px;
  height: 145px;
  transition: 1s;
  /* background: white; */
}
.vacancy-backgr-container {
  position: relative;
  width: 100%;
}
.vacancy-backgr {
  position: absolute;
  top: -10rem;
  z-index: -10;
  width: 100%;
}

.header2_vacancy_container {
  min-width: var(--min-widht);
  width: var(--max-widht);
  padding: 0 4.375rem;
  margin: 22rem auto 80px auto;
}
.header2_vacancy_container h1 {
  width: max-content;
  white-space: pre-wrap;
}
.header2_vacancy_description {
  min-width: var(--min-widht);
  width: var(--max-widht);
  padding: 0 4.375rem;
  margin: 0 auto;
}
.header2_vacancy_description p {
  color: var(--primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  white-space: pre-wrap;
  /* width: 50%; */
  display: flex;
  justify-content: flex-end;
  margin-bottom: 80px;
}
/*  */
.internship_vacancy_container {
  min-width: var(--min-widht);
  width: var(--max-widht);
  padding: 0 4.375rem;
  margin: 0 auto;
}
.internship_vacancy_item {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
  width: 100%;
  white-space: pre-wrap;
  border-bottom: 1px solid var(--line-vacancy);
  padding-bottom: 26px;
}
.internship_vacancy_item > div {
  width: 191px;
}
.internship_vacancy_item > div:not(:last-child) p:first-child {
  color: var(--accent-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* padding: 16px; */
}
.internship_vacancy_item
  > div:not(:last-child)
  p:first-child
  ~ .btn-flash-circle {
  top: -60px;
  left: -75px;
  width: 150px;
  height: 150px;
  transition: 1s;
  /* background: white; */
}
.internship_vacancy_item > div:not(:last-child) p:not(:first-child) {
  color: var(--third-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  padding-top: 16px;
}
.internship_vacancy_item > div:last-child {
  align-self: center;
  width: auto;
  margin-right: 79px;
  /* background: white; */
}

.btn-flash-circle {
  position: absolute;
  top: -55px;
  left: -70px;
  width: 146px;
  height: 145px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0892df;
  filter: blur(150px);
  z-index: -1;
  transition: 1s;
  /* background: white; */
  /* border: 125px solid red; */
}

.internship_vacancy_item > div:last-child:hover .btn-flash-circle {
  width: 365px;
  height: 145px;
  transition: 1s;
  /* border: 125px solid green; */
}
/*  */

/* start media */
@media (width < 1440px) {
  #vacancy {
    min-width: 100%;
    width: 100vw;
    padding: 100px 50px 0 50px;
  }
  .header_vacancy h1,
  .header2_vacancy h1 {
    font-size: 55px;
  }
  .header_vacancy_container,
  .internship_vacancy_container {
    display: block;
  }
  .header_vacancy_container p {
    margin-left: 0%;
    width: 100%;
    margin-bottom: 65px;
  }
  .container_vacancy {
    min-width: var(--min-widht);
    width: var(--max-widht);
    padding: 0 4.375rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 100px 50px;
    border: #0ecde7 1px solid;
  }
  .vacancy_item {
    all: unset;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    white-space: pre-wrap;
    padding: 10px;
    border: 1px solid var(--line-vacancy);
  }
  .vacancy_item:not(:first-child) {
    padding: 10px;
  }
  .vacancy_item > div:first-child {
    text-align: center;
    align-self: center;
    width: 100%;
    /* padding: 10px; */
  }
  .vacancy_item > div:not(:first-child):not(:last-child) {
    text-align: center;
    align-self: center;
    padding-bottom: 25px;
  }
  .vacancy_item > div:last-child {
    align-self: center;
    align-items: center;
    margin-right: 0px;
  }

  .header2_vacancy_container,
  .header2_vacancy_description p {
    margin-bottom: 40px;
  }
  .header2_vacancy_container {
    margin-top: 100px;
  }
  .internship_vacancy_item {
    /* all: unset; */
    display: flex;
    justify-content: center;
    align-content: center;
    min-width: 100%;
    flex-wrap: wrap;
    gap: 20px 20px;
  }
  .internship_vacancy_item > div:last-child {
    margin: 0 220px;
  }
  .vacancy-backgr-container {
    display: none;
  }
}
@media (width <756px) {
  .header2_vacancy h1 {
    font-size: 55px;
    width: 100%;
    white-space: wrap;
  }
  .header_vacancy h1 {
    font-size: 55px;
  }
}
@media (width <520px) {
  .internship_vacancy_item > div:last-child {
    margin: 0;
  }
  .internship_vacancy_container {
  }
  .internship_vacancy_item {
  }
}

@import "./components/navbar.module.css";
@import "./components/accordion.module.css";

:root {
  --max-widht: 1440px;
  --min-widht: 1000px;
  --primary-color: #7f7f7f;
  --secondary-color: #f7f7f7;
  --third-color: #656565;
  --fourth-color: #6c6c6c;
  --accent-color: #0ecde7;
  --accent2-color: #183070;
  --background-color: #000000;
  --button-border: #1cb2c7;
  --line-vacancy: #343434;
}
html {
  font-size: 16px;
}
body {
  background-color: var(--background-color);
  color: var(--primary-color);
  font-family: Nunito Sans, sans-serif;
  overflow: scroll;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*border: 1px solid green;*/
}
ul {
  list-style: none;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end:0;
}
a {
  text-decoration: none;
}
button {
  outline: none;
  border: none;
}
input {
  outline: none;
  border: none;
}
.main_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
}
.header_text_gradient {
  position: relative;
  font-family: JetBrains Mono, sans-serif;
  font-size: 5.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: linear-gradient(
    270deg,
    #fff 4.07%,
    rgba(255, 255, 255, 0) 148.86%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 8;
}
.flash_container {
  position: relative;
}
.flash {
  position: absolute;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0892df;
  filter: blur(150px);
  z-index: 1;
  transition: 1s;
  /* border: 125px solid red; */
}
.btn_flash {
  top: -50px;
  left: -75px;
  width: 145px;
  height: 145px;
}
.btn_container div:first-child {
  width: max-content;
}
.btn_container div:first-child:hover .btn_flash {
  width: 305px;
  height: 145px;
}

/* start media */
@media (width <1440px) {
  html {
    font-size: 15px;
  }
}
@media (width <1200px) {
  html {
    font-size: 14px;
  }
}
@media (width <1000px) {
  html {
    font-size: 14px;
  }
  .header_text_gradient {
    font-size: 4rem;
  }
}
@media (width <800px) {
  html {
    font-size: 13px;
  }
}
@media (width <600px) {
  html {
    font-size: 12px;
  }
  .header_text_gradient {
    font-size: 3.2rem;
    line-height: 130%;
  }
}
@media (width <500px) {
  html {
    font-size: 11px;
  }
}
@media (width <420px) {
  html {
    font-size: 10px;
  }
}


#footer {
  min-width: var(--min-widht);
  width: var(--max-widht);
}
#footer {
  /* width: 100%; */
  /* padding: 100px 0 0 0; */
  /* border: 1px solid green; */
}
#footer .main-content-area {
  /* border: 1px solid red; */
  /* display: -ms-grid; */
  display: grid;
  -ms-grid-columns: (minmax(230px, 1fr)) [auto-fit];
  /* grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));*/
  grid-template-columns: 300px 300px 700px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-gap: 10px;
  margin-bottom: 33px;
}
.footer_headers h3,
.footer_headers2 h3 {
  /* border: 1px solid red; */
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  /* color: #000; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.footer_headers2 {
  display: none;
}
#footer .main-content-area .holder ul {
  padding: 0;
  grid-gap: 10px;
}
#footer .main-content-area .holder ul li {
  list-style: none;
}
#footer .main-content-area .holder ul li a,
#footer .main-content-area .holder span {
  display: inline-block;
  text-decoration: none;
  padding: 0.3rem 0;
  color: #4d4d4d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
.footer_headers,
.footer_headers2 {
  padding: 0;
  list-style: none;
}
#footer .main-content-area .holder ul li a:hover {
  font-weight: 800;
  cursor: pointer;
  color: #fff;
}
#footer .copyright {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.mincifra {
  display: block;
  width: 700px;
  grid-auto-flow: dense;
}

.mincifra img {
  width: 192.003px;
  height: 39.999px;
  flex-shrink: 0;
}
.mincifra span {
  display: inline-block;
  color: #4d4d4d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 42px 10px 0;
  padding: 0;
}
.telegram {
  display: grid;
  /* text-align: center;
  align-items: center; */
}
.telegram ul {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 65px;
  /* color: #000; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  padding: 0;
  margin: 0;
}
.telegram ul li {
  width: 190px;
  justify-items: center;
  color: #fff;
}
.contacts ul {
  display: inline-grid;
  width: 50%;
}
.row-border {
  border-top: 1px solid var(--line-vacancy);
  grid-column: 1 / 4;
}
@media (width < 1440px) {
  #footer {
    min-width: 100%;
    width: 100vw;
    padding: 100px 50px 0 50px;
  }
  #footer .main-content-area {
    display: grid;
    /* -ms-grid-columns: (minmax(230px, 1fr)) [auto-fit]; */
    /* grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));*/
    /* grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 200px; */
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr;
    gap: 0px 0px;
    grid-template-areas:
      "links navigation"
      "contacts contacts";
  }
  .links {
    grid-area: links;
  }
  .contacts {
    grid-area: contacts;
  }
  .navigation {
    grid-area: navigation;
  }

  #footer .copyright {
    display: grid;
    /* -ms-grid-columns: (minmax(230px, 1fr)) [auto-fit]; */
    /* grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));*/
    /* grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 200px; */
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "mincifra"
      "telegram";
  }
  .mincifra {
    grid-area: mincifra;
  }
  .telegram {
    grid-area: telegram;
  }

  .footer_headers {
    display: none;
  }
  .footer_headers2 {
    display: block;
  }
  .mincifra {
    /* display: block; */
    width: 100%;
    /* grid-auto-flow: dense; */
  }
  .telegram ul,
  .telegram ul li {
    width: 100%;
    grid-gap: 0;
  }
}
@media (width < 425px) {
  .telegram ul,
  .telegram ul li {
    display: block;
  }
}

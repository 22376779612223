.accordion__item {
  margin-bottom: 0.5rem;
  width: 65rem;
  border-bottom: 1px solid #404040;
}

.accordion__header {
  margin-top: 1.56rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
}
.accordion__header span {
  min-width: 2.3rem;
  color: #808080;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 4rem;
}
.accordion__header h2 {
  color: var(--primary-color);
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 0.25rem;
}
.accordion__header::after {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  background-image: url("../images/arrow-acc.svg");
  background-repeat: no-repeat;
  background-size: 1.9rem;
  content: "";
  transition: transform 0.3s ease-out;
}

.accordion__item_show .accordion__header::after,
.accordion__item_slidedown .accordion__header::after {
  transform: rotate(135deg);
}

.accordion__content {
  margin: 0;
  padding: 2.5rem 7rem 1.5rem 13.625rem;
  white-space: pre-wrap;
}

.accordion__item:not(.accordion__item_show) .accordion__body {
  display: none;
}
@media (width<1440px) {
  .accordion__item {
    width: 100%;
  }
}
@media (width<1000px) {
  .accordion__header {
    margin-top: 1.56rem;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
  }
  .accordion__header h2 {
    width: 60%;
    color: var(--secondary-color);
    font-size: 1.8rem;
  }
  .accordion__header::after {
    width: 4rem;
    height: 4rem;
  }
  .accordion__content {
    margin: 0;
    padding: 2.5rem 0 1.5rem 0;
    white-space: pre-wrap;
    font-size: 1.4rem;
  }
}
@media (width<380px) {
  .accordion__header h2 {
    width: 80%;
  }
}

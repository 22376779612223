.technologies {
  min-width: var(--min-widht);
  width: var(--max-widht);
  padding: 0 4.375rem;
  margin: 6.25rem 0 10.75rem 0;
}
.header_technologies_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.header_technologies h1 {
  width: max-content;
  white-space: pre-wrap;
}
.header_technologies_container p {
  color: var(--primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  width: 50%;
  margin-left: 5%;
  margin-bottom: 148px;
  padding-top: 10px;
}

/*  */
.container_technologies_tags {
  width: 100%;
}
.technologies_tags {
  max-width: 750px;
  width: 50%;
  /* height: 12rem; */
}
.technologies_tags li {
  display: inline-flex;
}
.technologies_tags li span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
  padding: 4px 16px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 10px;
  cursor: default;
}
.technologies_tags span:hover {
  color: var(--accent-color);
}
.active_tag {
  color: var(--accent-color);
}
/*  */

.technologies_items {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid_list {
  width: 64%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  margin-left: 10%;
}
.grid_list_items {
  width: 15rem;
}
.grid_list_items span {
  color: var(--primary-color);
  font-family: JetBrains Mono, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: default;
}
.grid_list_items span:hover {
  color: var(--secondary-color);
}
.grid_list_items span:hover:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 6px;
  margin-right: 12px;
  background: var(--secondary-color);
}
/* .grid_list_items:hover {
  width: 4px;
  height: 6px;
  background: white;
}
.grid_list_items:hover:before {
  content: ".";
} */

/* start media */
@media (width < 1440px) {
  .technologies {
    min-width: 100%;
    width: 100%;
  }
}
@media (width < 1000px) {
  .technologies {
    padding:  0 1.6rem;
    margin: 7.2rem 0 4.5rem 0;
  }
  .header_technologies_container  {
    display: flex;
    align-items: flex-start;
    justify-content: start;
    flex-direction: column;
    margin-bottom: 0;
  }
  .header_technologies_container p {
    font-size: 1.4rem;
    width: 100%;
    margin-left: 0;
    margin-bottom: 4rem ;
  }
  .grid_list {
    width: 100%;
    gap: 1.6rem 3rem;
    margin-left: 0;
  }
  .grid_list_items span {
    color: var(--secondary-color);
    font-size: 1.8rem;
  }
  .technologies_tags {
    max-width: 100%;
    width: 100%;
  }
  .technologies_tags li span {
    margin: 0;
    padding: 4px 16px;
    font-size: 1rem;
  }
}

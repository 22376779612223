/* .input_wrapper {
  display: flex;
  margin-bottom: 40px;
  position: relative;
}
.input,
.label {
  display: block;
}
.input {
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid #343434;
  color: var(--secondary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 8px 0;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 30px;
  outline: 0;
}

.label {
  background-color: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  margin-bottom: -24px;
  position: absolute;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  bottom: 2em;
  pointer-events: none;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.input_wrapper .input:not(:placeholder-shown) + .label,
.input_wrapper .input:focus + .label {
  bottom: 175%;
  font-size: 75%;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
*/

/*  */
.input_wrapper {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 50%;
}
.input {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--line-vacancy);
  outline: 0;
  font-size: 1rem;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.3s;
}
.input::placeholder {
  color: transparent;
}
.input:placeholder-shown ~ .label {
  font-size: 1rem;
  cursor: text;
  top: 20px;
}

.label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.3s;
  font-size: 1rem;
  color: #9b9b9b;
}

.input:focus {
  border-width: 2px;
  border-image: linear-gradient(
    to right,
    var(--accent-color),
    var(--accent2-color)
  );
  border-image-slice: 1;
}
.input:focus ~ .label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.3s;
  /* font-size: 1rem; */
  color: #11998e;
}

/* reset input */
.input:required,
.input:invalid {
  box-shadow: none;
}
.labelText {
  color: var(--secondary-color);
}

.labelRequired {
  margin-left: 5px;
  color: var(--accent-color);
}

.textLength {
  position: absolute;
  top: 70px;
  color: var(--primary-color);
}

#your_project {
  min-width: var(--min-widht);
  width: var(--max-widht);
  padding: 100px 0 0 0;
}
#your_project > div {
  width: 100%;
}
.header_your_project {
  width: max-content;
  white-space: pre-wrap;
}
.container_your_project {
  display: flex;
  justify-content: flex-end;
}
.container_your_project
  div:not(
    .star1_yr_project_flash,
    .star2_yr_project_flash,
    .star3_yr_project_flash,
    .btn_yr_project_flash,
    .yr_btn
  ) {
  width: 524px;
  margin-right: 250px;
}
input:focus {
  outline: none;
}
.attachment {
  display: block;
  /*align-items: center;*/
}
.attachment div {
  width: auto;
}
.attachment div label {
  display: flex;
  justify-content: start;
  align-items: end;
  margin-top: 1rem;
  border-bottom: 2px solid var(--line-vacancy);
}
.attachment div label span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--secondary-color);
  margin-bottom: 10px;
  margin-right: 32px;
}
.attachment div label img {
  width: 44px;
  height: 44px;
  transform: rotate(0deg);
  flex-shrink: 0;
}
.attachment div label img:hover {
  filter: invert(69%) sepia(0%) saturate(40%) hue-rotate(180deg) brightness(98%)
    contrast(94%);
}
.attachment-input {
  opacity: 0;
  z-index: -1;
  height: 1px;
}
.flash_container {
  position: relative;
}
.yr_project_flash {
  position: absolute;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0892df;
  filter: blur(150px);
  z-index: -1;
  transition: 1s;
}
.btn_yr_project_flash {
  top: -50px;
  left: -75px;
  width: 145px;
  height: 145px;
}
.yr_project_btn_container div:first-child {
  width: max-content;
}
.yr_project_btn_container div:first-child:hover ~ .btn_yr_project_flash {
  width: 335px;
  height: 145px;
}
.star1_yr_project_flash {
  border-radius: 50%;
  top: -55px;
  left: -25px;
  width: 125px;
  height: 125px;
}
.star2_yr_project_flash {
  top: -55px;
  left: -5px;
  width: 125px;
  height: 125px;
}
.star3_yr_project_flash {
  top: -55px;
  left: 270px;
  width: 125px;
  height: 125px;
}

/* start media */
@media (width < 1440px) {
  #your_project {
    min-width: 0;
    width: auto;
    padding: 100px 50px;
  }
  #your_project > div h1 {
    text-align: center;
    white-space: normal;
  }
  .header_your_project {
    display: flex;
    width: 100%;
  }
  .header_your_project h1 {
    /* width: min-content; */
    font-size: 55px;
  }
  .container_your_project {
    display: flex;
    justify-content: center;
    width: auto;
  }
  .container_your_project
    div:not(
      .star1_yr_project_flash,
      .star2_yr_project_flash,
      .star3_yr_project_flash,
      .btn_yr_project_flash,
      .yr_btn
    ) {
    margin-right: 0;
    width: 100%;
  }
  .yr_project_btn_container {
    display: flex;
    width: 100%;
    justify-content: center;
    /* border: 1px solid green; */
  }
}
@media (width <865px) {
  #your_project {
    padding: 100px 35px;
  }
}
@media (width <520px) {
  #your_project {
    padding: 50px 20px;
  }
}
@media (width<456px) {
  .header_your_project h1 {
    font-size: 36px;
  }
}

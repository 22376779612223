.cases {
  min-width: var(--min-widht);
  width: var(--max-widht);
  padding: 0 4.375rem;
  margin: 6.25rem 0 10.75rem 0;
}
.header_cases_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 9.25rem;
}
.header_cases_container p {
  align-items: center;
  color: var(--primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  width: 50%;
  padding-top: 10px;
}
.container_cases_tags {
  width: 100%;
  position: relative;
}
.cases_tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem 2rem;
  width: 40%;
}
.cases_tags li {
}
.cases_tags li span {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: default;
  width: auto;
}
.cases_tags span:hover {
  color: var(--accent-color);
}
.active_tag {
  color: var(--accent-color);
}
.cases_tags__count {
  color: #404040;
}
.cases_select {
  display: inline-flex;
  justify-content: center;
  border-bottom: 1px solid var(--accent-color);
  padding-top: 3.25rem;
}
.cases_select span {
  color: var(--accent-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0.375rem 0 0;
  padding: 0;
}
.cases_select:hover img {
  transform: rotate(180deg);
}
.container_cases_projects {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 0 6.25rem;
  margin-top: -7.8125rem;
}
.container_cases_projects .cases_project:nth-child(odd) {
  margin-top: 10.9375rem;
}
.cases_project {
  width: 34.3125rem;
  overflow: hidden;
}
.cases_project div {
  overflow: hidden;
}
.cases_project img {
  width: 34.3125rem;
  height: 45.6875rem;
  filter: grayscale(1) blur(0px);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
.cases_project img:hover {
  filter: grayscale(0) blur(0);
  transition: 1s;
  transform: scale(1.2);
}
.cases_project div:hover ~ .text_popap {
  visibility: visible;
}
.cases_project h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 16px;
  transition: color 0.5s ease;
}
.text_popap {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  visibility: hidden;
}
.button_center {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.flash_container {
  position: relative;
}
.cases_project_flash {
  position: absolute;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0892df;
  filter: blur(150px);
  z-index: -1;
  transition: 1s;
  /* border: 125px solid red; */
}
.btn_cases_project_flash {
  top: -50px;
  left: -75px;
  width: 145px;
  height: 145px;
}
.cases_project_btn_container div:first-child {
  width: max-content;
  margin-top: 2rem;
}
.cases_project_btn_container div:first-child:hover .btn_cases_project_flash {
  width: 305px;
  height: 145px;
}

/* start media */
@media (width < 1440px) {
  .cases {
    min-width: 100%;
    width: 100%;
  }
  .cases_project {
    width: 30rem;
    overflow: hidden;
  }
  .cases_project img {
    width: 30rem;
    height: 39.945rem;
  }
}
@media (width < 1100px) {
  .cases_project {
    width: 26rem;
    overflow: hidden;
  }
  .cases_project img {
    width: 26rem;
    height: 34.619rem;
  }
}
@media (width < 1000px) {
  .cases {
    padding:  0 1.6rem;
    margin: 7.2rem 0 0 0;
  }
  .container_cases_tags {
    margin: 4rem 0;
  }
  .cases_tags {
    width: 100%;
  }
  .header_cases_container {
    display: flex;
    align-items: flex-start;
    justify-content: start;
    flex-direction: column;
    margin-bottom: 0;
  }
  .header_cases_container p {
    font-size: 1.4rem;
    line-height: 160%;
    width: 100%;
  }
  .container_cases_projects {
    margin-top: 0;
    gap: 4rem;
  }
  .cases_project img {
    width: 26rem;
    height: 34.619rem;
    filter: grayscale(0) blur(0px);
  }
  .container_cases_projects .cases_project:nth-child(odd) {
    margin-top: 0;
  }
  .cases_select {
    display: none;
  }
  .text_popap {
    visibility: visible;
  }
}
@media (width < 880px) {
  .container_cases_projects {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 4rem;
  }
}
/* Start NavBar */
.nav_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  transition: 0.6s ease-in-out;
  height: 100px;
  box-sizing: border-box;
}
.nav_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--max-widht);
  padding: 10px 4.375rem;
}
.menu-start {
  display: flex;
  justify-content: start;
  align-items: center;
}
.logo {
  margin-top: -6px;
  margin-right: 48px;
  margin-left: 10px;
  width: 150px;
  height: 34px;
}
.menu-links {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 3rem;
}
.menu-links li a {
  display: flex;
  color: var(--primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.2s ease-in-out;
}
.menu-end {
  display: flex;
  justify-content: end;
  align-items: center;
}
.menu-buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 3rem;
}
.menu-end li {
  display: flex;
  color: var(--primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.2s ease-in-out;
}
.menu-button {
  padding: 0;
  border: none;
  background-color: transparent;
  margin-left: 0;
  margin-right: 0.8rem;
}
.menu-icon {
  width: 40px;
  height: 40px;
}
.lang_mobile {
  color: var(--secondary-color);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: default;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  font-size: 12px;
  font-weight: 700;
  color: #0ecde7;
  z-index: 12;
}
/* End NavBar */

/* ****** links Underline for NavBar ******* */
.linkmenu {
  display: block;
  position: relative;
  transition: 0.3s;
  cursor: default;
}
.linkmenu::after {
  content: "";
  position: absolute;
  bottom: -8px;
  width: 5px;
  height: 4px;
  background-color: var(--secondary-color);
  transition: opacity 0.3s, transform 0.3s;
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
  left: 50%;
}
.linkmenu:hover::after,
.linkmenu:focus::after {
  transform: scale(1);
}
.linkmenu:hover {
  color: var(--secondary-color);
}
/* end */

/* start media */
@media (width < 1440px) {
  .nav_wrapper {
    width: 100%;
    min-width: 100%;
  }
}
@media (width < 1000px) {
  .nav_container {
    height: 64px;
  }
  .nav_wrapper {
    width: 100%;
    min-width: 100%;
    padding: 10px 1.6rem;
  }
  .logo {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    width: 130px;
    height: 20px;
  }
}

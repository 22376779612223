.encoded_header_white {
  display: flex;
  flex-wrap: nowrap;
  font-family: JetBrains Mono, sans-serif;
  font-size: 5.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;
  color: var(--secondary-color);
  letter-spacing: 1px;
  z-index: 8;
  height: 109px;
}
.encoded_header_en {
  width: 52rem;
}
.encoded_header_ru {
  width: 42rem;
}
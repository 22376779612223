.digits-wrapper {
  position: relative;
  width: 100%;
  margin-top: 100px;
  padding: 0;
}
.header_item_digits {
  color: var(--accent-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  max-width: 5rem;
}
.digits-backgr-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: var(--max-widht);
  padding: 0 4.375rem;
  margin: 0 auto;
}
 .digits-wrapper > div ul {
  display: flex;
  gap: 1.875rem;
}
 .digits-wrapper > div ul li:first-child,
 .digits-wrapper > div ul li:last-child {
  margin-right: 0;
}
 .digits-wrapper > div > ul > li > div > h1 {
  color: var(--secondary-color);
  font-family: JetBrains Mono, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 10px;
  padding: 0;
}
 .digits-wrapper > div > ul > li > div > span {
  color: var(--secondary-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
 .digits-wrapper > div ul li p {
  color: var(--primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin: 0;
  width: 18.875rem;
}
.digits-backgr {
  position: absolute;
  top: 6rem;
  z-index: -10;
  width: 100%;
}

@media (width < 1440px) {
  .digits-backgr-container {
    width: 100%;
    min-width: 100%;
  }
}
@media (width < 1200px) {
  .digits-backgr-container {
    flex-direction: column;
    justify-content: start;
  }
}
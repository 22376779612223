.about {
  min-width: var(--min-widht);
  width: var(--max-widht);
  padding: 0 4.375rem;
  margin: 0 0 6.25rem 0;
}
.header_about_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8.5rem;
}
.header_about_container p {
  color: var(--primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  width: 50%;
  margin-left: 5%;
  padding-top: 10px;
}
/*  */

.container_about {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 12.5rem;
  width: 100%;
}
.about_company_image {
  width: 53.5625rem;
  height: 35rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.btn_presentation {
  display: flex;
  flex-wrap: wrap;
  margin-left: 8.75rem;
}
.container_about_text {
  width: 302px;
  margin-bottom: 40px;
  color: #7f7f7f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
/*  */
.container_about_principles {
  display: flex;
  justify-content: space-between;
}
.principles_title {
  text-transform: uppercase;
  color: var(--accent-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: start;
}
.principles_list {
  margin-right: 100px;
}
.principles_list_item {
  display: flex;
  padding-inline-start: 0;
  flex-wrap: nowrap;

  margin-bottom: 80px;
  border-bottom: 1px solid var(--line-vacancy);
}
.principles_list_item div {
  /* display: flex; */
}
.principles_list_item div div {
  display: flex;
  flex-wrap: nowrap;
  color: #808080;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 18px 50px 0 20px;
  width: 110px;
}
.principles_list_item div h3 {
  color: #f7f7f7;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 488px;
  padding-bottom: 24px;
}
.principles_list_item div p {
  color: #7f7f7f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  width: 488px;
  padding-bottom: 24px;
}
/*  */
.more_about_us {
  display: flex;
  justify-content: flex-end;
  padding-right: 30%;
}
.more_about_us div {
  /* color: #000; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px;
}
.more_about_us img {
  width: 30px;
  transform: rotate(-90deg);
  flex-shrink: 0;
}

/* start media */
@media (width < 1440px) {
  .about {
    min-width: 100%;
    width: 100%;
  }
  .about_company_image {
    width: 45.91rem;
    height: 30rem;
  }
  .btn_presentation {
    display: flex;
    flex-wrap: wrap;
    margin-left: 7.5rem;
  }
  .header_about_container p {
    width: 40%;
    margin-left: 5%;
  }
}
@media (width < 1150px) {
  .about_company_image {
    width: 38.2589rem;
    height: 25rem;
  }
  .btn_presentation {
    margin-left: 3rem;
  }
}
@media (width < 1000px) {
  .about {
    padding:  0 1.6rem;
    margin: 0 0 4.5rem 0;
  }
  .header_about_container {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2.5rem;
  }
  .header_about_container p {
    font-size: 1.4rem;
    width: 100%;
    margin-left: 0;
    padding-top: 1.5rem;
  }
  .container_about {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1.6rem;
    margin-bottom: 1.5rem;
  }
  .about_company_image {
    width: 27rem;
    height: 27rem;
  }
  .container_about_text {
    display: none;
  }
  .btn_container {
    margin-left: -2.5rem;
  }
  .container_about_principles {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .principles_title {
    margin-bottom: 1.5rem;
  }
  .principles_list {
     margin-right: 0;
   }
  .principles_list_item {
    border: 1px solid var(--line-vacancy);
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    padding: 1.6rem 1.6rem 0 1.6rem ;
    width: 24.8rem;
  }
  .principles_list_item div div {
    padding: 0;
  }
  .principles_list_item div h3 {
    font-size: 1.8rem;
    width: 21.8rem;
  }
  .principles_list_item div p {
    font-size: 1.4rem;
    width: 21.8rem;
  }
}
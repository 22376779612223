.darkBG {
  background: -moz-linear-gradient(top, #000 0%, grey 100%);
  background: -webkit-linear-gradient(top, #000 0%, grey 100%);
  filter: blur(25px);
  width: 100vw;
  height: 3000vh;
  z-index: 8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.blurBG {
  background-color: rgba(0, 0, 0, 0.8);
  filter: blur(1px);
  top: 0;
  left: 0;
  z-index: 20;
  width: 100vw;
  height: 100%;
  position: fixed;
}
.darkBG:hover {
  background-position: 100%;
}
.centered {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 25;
}
.logo {
  margin-top: -6px;
  width: 150px;
  height: 34px;
}
/* Первый модал */
.modalMainMenu {
  width: 100vw;
  height: 100vh;
  background: var(--background-color);
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-size: 100% 100%;
}
.modalHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 10px 1.6rem;
}
.mainMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--secondary-color);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 60%;
  margin: 4.8rem auto;
}
.mincifra {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4.8rem auto;
  gap: 8px;
}
.mincifra_logo {
  width: 15.4rem;
  height: 3.2rem;
}
.mincifra_text {
  font-size: 1rem;
}
.digits-mobile_wrapper {
  position: relative;
  width: 100%;
  min-width: 100%;
  margin-top: 7.7rem;
  padding: 0 1.6rem;
}
.digits-backgr-container_mobile {
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-bottom: 10rem;
}
.header_item_mobile {
  color: var(--accent-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.digits-backgr-container_mobile > ul {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 1.6rem;
  margin-top: 1.6rem;
  /*width: 79.2rem;*/
}
.digits-backgr-container_mobile > ul > li {
  border: 1px solid var(--line-vacancy);
  justify-content: start;
  padding: 2.4rem 1.6rem;
  width: 24.8rem;
  height: 17.2rem;
}
.digits-backgr-container_mobile > ul > li > div > h1 {
  color: var(--secondary-color);
  font-family: JetBrains Mono, sans-serif;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 0;
}
.digits-backgr-container_mobile > ul > li > div > span {
  color: var(--secondary-color);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.digits-backgr-container_mobile > ul > li > p {
  color: var(--primary-color);
  margin-top: 2.4rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 21.6rem;
}
.digits-backgr_mobile {
  position: absolute;
  top: 2.4rem;
  left: 0;
  width: 81rem;
  height: 39.93rem;
  z-index: -10;
}

.darkBG {
  /* background-color: rgba(0, 0, 0, 0.747); */
  /* background-color: var(--accent-color); */
  /* background-image: url("../images/bg3.png"); */
  /* background-image: linear-gradient(to right, black, grey 800px, black 1600px); */
  /* background-size: 1600px 100%; */
  /* background-position: 50% 100%; */
  /* background-repeat: no-repeat; */
  /* background-repeat: repeat-y; */
  /* background-size: contain; */

  background: -moz-linear-gradient(top, #000 0%, grey 100%);
  background: -webkit-linear-gradient(top, #000 0%, grey 100%);

  filter: blur(25px);
  width: 100vw;
  height: 3000vh;
  z-index: 8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.blurBG {
  background-color: rgba(0, 0, 0, 0.8);
  filter: blur(1px);
  top: 0;
  left: 0;
  z-index: 20;
  width: 100vw;
  height: 100%;
  position: fixed;
}
.darkBG:hover {
  background-position: 100%;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 25;
}
/* Первый модал */
.modalINeed {
  width: 1440px;
  height: 900px;
  background: var(--background-color);
  border: 1px solid var(--accent-color);
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3.6% 5%;
  background-size: 100% 100%;
}
.modalHeader {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.modalHeader_text {
  display: flex;
  height: 44px;
  color: #fff;
  font-family: JetBrains Mono, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  align-items: center;
}
.modalHeader_tags_img {
  display: flex;
  justify-content: center;
  width: 50%;
}
.selectedTags {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  gap: 22px 20px;
}
.selectedTag {
}
.modalHeader_tags_img img {
  display: flex;
  cursor: pointer;
}
.selectedTags div {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--accent-color);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5px 18px;
}
.selectedTags div:hover {
  background-color: var(--accent-color);
  cursor: pointer;
}
.modalINeedContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.modalINeedContent div {
  width: 350px;
}
.modalINeedContent div p {
  color: var(--accent-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalINeedContent div h2 {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
  text-transform: capitalize;
  z-index: 40;
}
.modalINeedContent .step1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modalINeedContent .step2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modalINeedContent .step3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* width: 30rem; */
}
.modalINeedContent .tags {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 22px 20px;
}
.modalINeedContent .tags div {
  width: fit-content;
  color: var(--fourth-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: inline-flex;
  padding: 5px 18px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--fourth-color);
  border-radius: 5px;
  cursor: pointer;
}
.modalINeedContent .tags div:hover {
  /* border: 1px solid var(--accent-color); */
  /* background: var(--accent-color); */
  background: #fff;
  color: #000;
}
.modalINeedContent .tags .active_task {
  color: #fff;
  border: 1px solid #fff;
}
/*  */
.container_contacts {
  display: flex;
}
.name_company {
  display: inline-flex;
}
.name_company div {
  margin-right: 30px;
  /* border: 1px solid green; */
}

.container_contacts div:last-child:not(.checkbox) {
  margin-right: 0;
}
/* .container_contacts div input {
  display: block;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #000;
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} */
/* input:focus {
  outline: none;
} */
.contacts_attachment,
.can_messenger {
  display: block;
  align-items: center;
  width: 100%;
}
.contacts_attachment div,
.can_messenger div {
  display: inline-flex;
  /* width: fit-content; */
  width: 300px;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #000;
}
.contacts_attachment div span,
.can_messenger div span {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 15px 0;
  width: 100%;
}
.contacts_attachment div img {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: flex-end;
  cursor: pointer;
}
.can_messenger .checkbox {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: flex-end;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid var(--accent-color);
  accent-color: var(--accent-color);
  margin-right: 10px;
}
/*  */
.modalINeed .actionsContainer {
  display: block;
}
.text {
  color: var(--fourth-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
  padding-right: 10px;
  z-index: 35;
}
.modalINeed .sendBtn {
  display: flex;
  width: 221px;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 22px 20px;
  background: transparent;
  color: var(--fourth-color);
  border: 1px solid var(--accent-color);
  text-transform: uppercase;
}
.sendBtn:hover {
  /* background-color: var(--accent-color);
  border: 1px solid var(--accent-color); */
  transition: all ease 0.5s;
  color: #fff;
}
.next_steps {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7.2% 0 0 0;
  gap: 10px;
}
.next_step {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.next_step_hide {
  display: none;
}
.next_step_text {
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 14px;
}
.next_step_img {
  transform: rotate(270deg);
}
.previous_step_img {
  transform: rotate(90deg);
}
.modal_elipse {
   position: absolute;
   top: -50px;
   left: -100px;
   width: 429px;
   height: 182px;
   flex-shrink: 0;
   border-radius: 429px;
   background: #0892df;
   filter: blur(250px);
   z-index: 30;
 }
.modal_elipse_btn {
  position: absolute;
  bottom: 0;
  right: 100px;
  width: 429px;
  height: 182px;
  flex-shrink: 0;
  border-radius: 429px;
  background: #0892df;
  /*filter: blur(250px);*/
  z-index: 0;
}
/* start media */
@media (width < 1440px) {
  .modalINeed {
    width: 98vw;
    padding: 3.6% 2.5%;
  }
}
@media (width < 1200px) {
  .modalINeed {
    width: 450px;
    min-height: 92vh;
    height: auto;
    padding: 7.2% 10%;
  }
  .modalHeader_tags_img {
    width: 100%;
    padding: 20px 0;
    height: 50%;
  }
  .selectedTags div {
    padding: 5px 18px;
  }
  .text {
    width: 100%;
    padding-top: 15px;
  }
}
@media (width < 500px) {
  .modalINeed {
    width: 380px;
    padding: 3.6% 5%;
  }
  .next_steps {
    margin-left: -15px;
  }
  .modalINeedContent div {
    width: 350px;
  }
}

/* Второй модал */
.modalConfirmation {
  width: 700px;
  height: 480px;
  flex-shrink: 0;
  background: var(--background-color);
  border: 1px solid var(--accent-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  user-select: none;
  background-size: 100% 100%;
}
.modalConfirmationContent h2 {
  color: #fff;
  font-family: JetBrains Mono, sans-serif;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  z-index: 28;
}
.modalConfirmationContent span {
  display: inherit;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-align: center;
}
.contact_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-align: center;
  gap: 40px;
  margin-bottom: 40px;
}
.contacts {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 70%;
}
.contacts ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.telegram {
  width: 70%;
}
.telegram ul {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.modalConfirmation .actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  user-select: none;
  position: relative;
}
/* .modalConfirmation .OKBtn {
  display: flex;
  width: 221px;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #fff;
  color: #000;
  border-radius: 5px;
  border: 1px solid #fff;
  user-select: none;
}
.OKBtn:hover {
  background-color: #000;
  transition: all ease 0.5s;
  color: #fff;
  border: 1px solid #fff;
} */

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: true;
}

:root {
  --d: 1000ms;
  --angle: 100deg;
  --gradX: 80%;
  --gradY: 50%;
  --c1: #1cb2c7;
  --c2: #183070;
}

.box {
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */

  color: #9a9b9b;
  /* font-family: Nunito Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;

  border: 1px solid var(--c2);
  /* padding: 1vw; */
  /* border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--c1) 0.1turn,
      var(--c1) 0.2turn,
      var(--c1) 0.3turn,
      var(--c1) 0.4turn,
      var(--c2) 0.5turn
    )
    20; */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2) 0turn,
      var(--c1) 0.3turn,
      var(--c1) 0.6turn,
      var(--c2) 1turn
    )
    20;

  /* radial-gradient */

  /* border-image: radial-gradient(
      ellipse at var(--gradX) var(--gradY),
      var(--c2) 30%,
      var(--c2),
      var(--c1),
      var(--c1) 70%
      )
      20; */
  z-index: 40;
}
.box:hover {
  /* border-image: conic-gradient(
      from var(--angle),
      var(--c2) 0turn,
      var(--c1) 0.3turn,
      var(--c1) 0.6turn,
      var(--c1) 0.9turn,
      var(--c1) 1.2turn,
      var(--c1) 1.5turn,
      var(--c1) 1.8turn,
        var(--c1) 2.1turn,
      var(--c1) 2.4turn,
      var(--c2) 2.7turn,
      var(--c2) 3turn
    )
    20; */
  animation: borderRotate var(--d) linear;
  /* border: 1px solid var(--c1);
  transition: all 1s ease; */
}

@keyframes borderRotate {
  100% {
    --angle: 450deg;
  }
}
@keyframes borderRadial {
  10% {
    --gradX: 100%;
    --gradY: 50%;
  }
  20% {
    --gradX: 100%;
    --gradY: 50%;
  }
  30% {
    --gradX: 100%;
    --gradY: 100%;
  }
  40% {
    --gradX: 100%;
    --gradY: 100%;
  }
  50% {
    --gradX: 50%;
    --gradY: 100%;
  }
  60% {
    --gradX: 50%;
    --gradY: 100%;
  }
  70% {
    --gradX: 0%;
    --gradY: 50%;
  }
  80% {
    --gradX: 0%;
    --gradY: 50%;
  }
  90% {
    --gradX: 50%;
    --gradY: 0%;
  }
  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}
@media (width <520px) {
  .box {
    padding: 16px 12px;
    font-size: 12px;
  }
}
.services-wrapper {
  width: var(--max-widht);
  padding: 10rem 4.375rem 0 4.375rem;
  margin-top: -6rem;
}
.container_services {
  display: flex;
  justify-content: space-between;
  margin-top: 9rem;
  margin-bottom: 3.5rem;
  width: 100%;
}
.container_services .title {
  min-width: 10rem;
  text-transform: uppercase;
  color: var(--accent-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: start;
  margin-top: 2.8rem;
}
.accordion {
  width: 60.123rem;
  margin-right: 4.375rem;
}
@media (width < 1440px) {
  .services-wrapper {
    width: 100%;
    min-width: 100%;
  }
}
@media (width <1000px) {
  .services-wrapper {
    padding: 7.2rem 1.6rem 0 1.6rem;
  }
  .container_services {
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin-top: 4rem;
    margin-bottom: 3.5rem;
    width: 100%;
  }
  .container_services .title {
    font-size: 1rem;
    margin-top: 0;
  }
  .accordion {
    width: 100%;
  }
}